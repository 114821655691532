import React from "react";

import { readState } from "@/__main__/app-state.mjs";
import ChampionAugmentsTable from "@/game-tft/ChampionAugmentsTable.jsx";
import ChampionHeader from "@/game-tft/ChampionHeader.jsx";
import ChampionItemsTable from "@/game-tft/ChampionItemsTable.jsx";
import ChampionOverview from "@/game-tft/ChampionOverview.jsx";
import {
  isFutureSet,
  isRevivalSet,
  tftSetsDropdown,
} from "@/game-tft/constants.mjs";
import getFilters from "@/game-tft/get-stats-filters.mjs";
import getUnitItemStats from "@/game-tft/get-unit-item-stats.mjs";
import StaticTFT from "@/game-tft/static.mjs";
import { useLocalizedChampions } from "@/game-tft/use-champions.mjs";
import useFilterStats from "@/game-tft/use-filter-stats.mjs";
import useSetByParam from "@/game-tft/use-set-by-param.mjs";
import { useLocalizedTraits } from "@/game-tft/use-traits.mjs";
import Container from "@/shared/ContentContainer.jsx";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function Champion() {
  const {
    parameters: [championKey, tabId],
    searchParams,
  } = useRoute();

  const state = useSnapshot(readState);
  const set = useSetByParam(true);
  const filter = useFilterStats();
  const champions = state.tft[set]?.champions;
  const localizedChampions = useLocalizedChampions(set);
  let champion = localizedChampions?.[championKey] ?? champions?.[championKey];

  const localizedTraits = useLocalizedTraits(set);

  if (!champion) return null;

  champion = {
    ...champion,
    key: champions[championKey].apiKey,
    name: champions[championKey].name,
  };

  const viewMode = "desktop";
  const championsStats = state.tft.stats.champions[filter]?.[set] ?? [];

  const indivUnitFilters =
    getFilters(
      searchParams,
      "indiv_units",
      isFutureSet(set),
      isRevivalSet(set),
    ) + `&api_name=${championKey}`;
  const indivUnitStats =
    state.tft.stats.indiv_units?.[indivUnitFilters]?.[set] ?? [];

  const traits = localizedTraits ?? state.tft[set]?.traits;
  const [classes, origins] = StaticTFT.getClassAndOriginsFromTraits(traits);
  const items = state.tft[set]?.items;
  const championClasses = [];
  const championOrigins = [];
  populateTraits(championClasses, champion.class, classes);
  populateTraits(championOrigins, champion.origin, origins);
  const unitItems = getUnitItemStats(championsStats, items, set, championKey);
  const unitItemsAvgPts =
    unitItems.reduce((acc, cur) => acc + cur.itemPoints, 0) / unitItems.length;
  const championImageMini = StaticTFT.getChampionImage(championKey);
  const dropdownEntries = Object.entries(tftSetsDropdown);
  const [dropdownKeys, dropdownValues] = dropdownEntries.reduce(
    (acc, cur) => {
      cur.forEach((i, idx) => acc[idx].push(i));
      return acc;
    },
    [[], []],
  );
  const dropdownPosition = Math.max(dropdownKeys.indexOf(set), 0);
  const tier = indivUnitStats?.tier || 5;

  return (
    <>
      <ChampionHeader
        champion={champion}
        championClasses={championClasses}
        championOrigins={championOrigins}
        championImageMini={championImageMini}
        selectedSet={set}
        tier={tier}
        dropdownValue={dropdownPosition}
        dropdownValues={dropdownValues}
        tab={tabId || "overview"}
      />
      <Container>
        {tabId === "overview" || !tabId ? (
          <ChampionOverview
            champion={champion}
            championClasses={championClasses}
            championOrigins={championOrigins}
            indivUnitStats={indivUnitStats}
            set={set}
            viewMode={viewMode}
          />
        ) : null}
        {tabId === "items" ? (
          <ChampionItemsTable
            indivUnitStats={indivUnitStats}
            itemsAvgPts={unitItemsAvgPts}
            champion={champion}
            set={set}
          />
        ) : null}
        {tabId === "augments" ? (
          <ChampionAugmentsTable
            indivUnitStats={indivUnitStats}
            itemsAvgPts={unitItemsAvgPts}
            champion={champion}
            set={set}
          />
        ) : null}
      </Container>
    </>
  );
}

export function meta() {
  return {
    title: [
      "tft:helmet.champion.title",
      "TFT Stats, Guide, Items, Synergies, & Ability — Blitz TFT",
    ],
    description: [
      "tft:helmet.champion.description",
      "Teamfight Tactics  TFT Guide, Build, Stats, Synergies, Best Items, How to play, & more!",
    ],
    subtitle: [
      "tft:helmet.champion.subtitle",
      "Teamfight Tactics  TFT Guide, Build, Stats, Synergies, Best Items, How to play, & more!",
    ],
  };
}

function populateTraits(target, arr, traits) {
  if ([target, arr].some((i) => !Array.isArray(i)) || !arr.length) return;
  const arrLength = arr.length;
  for (let i = 0; i < arrLength; i += 1) {
    const trait = traits?.[arr[i]];
    if (trait) target.push(trait);
  }
}
