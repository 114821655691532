import {
  ITEM_BLACKLIST,
  ITEM_SUGGESTION_BLACKLIST,
  scoreWeights,
} from "@/game-tft/constants.mjs";
import StaticTFT from "@/game-tft/static.mjs";
import clone from "@/util/clone.mjs";
import normalize from "@/util/normalize.mjs";
import orderArrayBy from "@/util/order-array-by.mjs";

export const calculatePointsItems = (
  avgPlacementScore = 0,
  top4RateScore = 0,
  winrateScore = 0,
  matchesPlayedScore = 0,
) => {
  let points = 0;

  // Weights
  const itemWeights = scoreWeights.items;
  const championWeights = scoreWeights.champions;

  // Calculate points based on the above normalized values
  points += Math.round(
    Math.pow(matchesPlayedScore, itemWeights.exponent.matchesPlayed) *
      itemWeights.multiply.matchesPlayed,
  );
  points +=
    Math.pow(
      avgPlacementScore - championWeights.subtract.avgPlacement,
      itemWeights.exponent.avgPlacement,
    ) * itemWeights.multiply.avgPlacement || 0;
  points +=
    Math.pow(
      top4RateScore - championWeights.subtract.top4Rate,
      itemWeights.exponent.top4Rate,
    ) * itemWeights.multiply.top4Rate;
  points +=
    Math.pow(winrateScore, itemWeights.exponent.avgPlacement) *
    itemWeights.multiply.winRate;

  return points;
};

function createMinMaxByKey(target, key, obj) {
  const min = "l" + key;
  const max = "h" + key;
  if (obj[key] < (target[min] ?? Number.MAX_SAFE_INTEGER))
    target[min] = obj[key];
  if (obj[key] > (target[max] ?? Number.MIN_SAFE_INTEGER))
    target[max] = obj[key];
}
const minMaxKeys = ["avgPlacement", "top4Rate", "winRate", "matchesPlayed"];

export const getItemSorted = (
  champItems,
  itemsStaticData,
  selectedSet,
  amountWanted = 100,
) => {
  const target = Object.create(null);
  const itemSetStaticData = itemsStaticData[selectedSet];
  if (!champItems || !itemsStaticData || !itemSetStaticData) return [];

  const itemsArr = [];

  // Create min-max keys in target object
  for (const itemId in champItems) {
    if (
      [ITEM_BLACKLIST, ITEM_SUGGESTION_BLACKLIST].some((arr) =>
        arr.includes(itemId),
      )
    ) {
      delete champItems[itemId];
    }
    const item = champItems[itemId];
    for (const key of minMaxKeys) {
      if (typeof item?.[key] === "undefined") continue;
      createMinMaxByKey(target, key, item);
    }
  }
  // Use min-max keys to calculate normalized values
  for (const itemId in champItems) {
    let itemStaticData;
    for (const key in itemSetStaticData) {
      const item = itemSetStaticData[key];
      if (item.id === itemId << 0) {
        itemStaticData = item;
        break;
      }
    }
    if (!itemStaticData) continue;

    const itemStats = champItems[itemId];

    // Avg placement normalization (0-1)
    const normAvgPlacement = normalize(
      8 - itemStats.avgPlacement,
      target["havgPlacement"],
      target["lavgPlacement"],
    );

    // Top4 rate normalization (0-1)
    const normTop4Rate = normalize(
      itemStats.top4Rate,
      target["htop4Rate"],
      target["ltop4Rate"],
    );

    // Win rate normalization (0-1)
    const normWinrate = normalize(
      itemStats.winRate,
      target["hwinRate"],
      target["lwinRate"],
    );

    // Matches played normalization (0-1)
    const normMatchesPlayed = normalize(
      itemStats.matchesPlayed,
      target["hmatchesPlayed"],
      target["lmatchesPlayed"],
    );

    // Calculate points based on the above normalized values
    const itemPoints = calculatePointsItems(
      normAvgPlacement,
      normTop4Rate,
      normWinrate,
      normMatchesPlayed,
    );

    itemsArr.push({
      id: itemId,
      info: itemStaticData,
      itemPoints,
      ...itemStats,
    });
  }

  if (!itemsArr.length) return itemsArr;
  orderArrayBy(itemsArr, ["itemPoints"], ["desc"]);

  if (amountWanted < itemsArr.length && amountWanted > 0) {
    return itemsArr.slice(0, amountWanted);
  }

  return itemsArr;
};

function addUnitToObj(obj, unit, { itemsStaticData, selectedSet }) {
  const unitName = StaticTFT.getChampName(unit[0]);
  const unitStats = unit[1];
  const unitItems = unitStats.items;
  obj[unitName] = getItemSorted(unitItems, itemsStaticData, selectedSet);
}

export const buildUnitItemStats = (
  championsStats,
  itemsStaticData,
  selectedSet,
  champion = "all",
) => {
  const itemStats = {};
  if (!Array.isArray(championsStats)) return itemStats;
  const unitsWithStats = clone(championsStats); // We don't want our static data being modified
  if (champion === "all") {
    for (const unit of unitsWithStats)
      addUnitToObj(itemStats, unit, { itemsStaticData, selectedSet });
  } else {
    const unit = unitsWithStats.find(
      (unit) => StaticTFT.getChampName(unit[0]) === champion,
    );
    if (!unit) return itemStats;
    addUnitToObj(itemStats, unit, { itemsStaticData, selectedSet });
  }
  return itemStats;
};

export default getItemSorted;
