import { styled } from "goober";
import { mobile, tablet } from "clutch/src/Style/style.mjs";

export const Columns = styled("div")`
  display: flex;
  margin-bottom: var(--sp-10);

  ${tablet} {
    flex-wrap: wrap;
  }
`;
export const Left = styled("div")`
  flex: 2;
  margin-right: var(--sp-2);
  display: flex;
  gap: var(--sp-4);
  flex-direction: column;

  ${tablet} {
    flex: 1;
  }

  ${mobile} {
    flex: unset;
    width: 100%;
    margin-right: 0;
  }
`;
export const Right = styled("div")`
  flex: 1;
  margin-left: var(--sp-2);
  display: flex;
  gap: var(--sp-4);
  flex-direction: column;

  ${tablet} {
    flex: unset;
    width: 100%;
    margin-left: 0;
    margin-top: var(--sp-4);
  }
`;
export const FullWidth = styled("div")`
  width: 100%;
`;

export const ChampionBackground = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 600px;
  background-repeat: no-repeat;
  background-position: 22% 90%;
  opacity: 0.15;
  border-radius: var(--br);

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(to top, var(--shade7) 0%, transparent 100%);
  }
`;

export const ChampsInClass = styled("div")`
  &:not(:last-of-type) {
    margin-bottom: var(--sp-4);
  }
`;

export const TypesHeader = styled("div")`
  align-items: center;
  margin-bottom: var(--sp-2);

  ${tablet} {
    margin-bottom: var(--sp-5);
  }
`;

export const CardElementHeader = styled("div")`
  margin-left: var(--sp-1);
  color: var(--shade3);
`;
