import getItemSorted from "@/game-tft/get-item-sorted.mjs";
import StaticTFT from "@/game-tft/static.mjs";

const getUnitItemStats = (
  championsStats,
  itemsStaticData,
  selectedSet,
  champion = "all",
) => {
  const itemStats = {};
  const unitStatsData = (championsStats && Object.values(championsStats)) || [];

  // Return ALL units/champions
  if (champion === "all") {
    unitStatsData.forEach((unit) => {
      const unitName = StaticTFT.getChampName(unit?.[0]);
      const unitStats = unit?.[1];
      const unitItems = unitStats?.items;

      const champSortedItems = getItemSorted(
        unitItems,
        itemsStaticData,
        selectedSet,
      );

      itemStats[unitName] = [...champSortedItems];
    });
  } else {
    const unit = unitStatsData.find((arr) => {
      const unit = arr?.[0];
      if (typeof unit !== "string") return false;
      const champKey = StaticTFT.fixChampKey(unit);
      return champKey.includes(champion);
    });
    const unitItems = unit?.[1].items;
    if (!unitItems) return [];
    return getItemSorted(unitItems, itemsStaticData, selectedSet);
  }

  return itemStats;
};

export default getUnitItemStats;
