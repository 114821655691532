import { useMemo } from "react";

import { STATS_FILTER_DEFAULT } from "@/game-tft/constants.mjs";
import { useRoute } from "@/util/router-hooks.mjs";

export function getRankRegionSort(searchParams) {
  // Apparently in blitz-web we don't even fetch for different sort types
  // We do a state sort for the existing results
  const sort = STATS_FILTER_DEFAULT.sort;
  const rank = searchParams.get("rank") || STATS_FILTER_DEFAULT.rank;
  const region = searchParams.get("region") || STATS_FILTER_DEFAULT.region;
  return [rank, region, sort];
}

export default function useFilterStats() {
  const { searchParams } = useRoute();
  const rankRegionSort = getRankRegionSort(searchParams);
  return useMemo(
    () => rankRegionSort.join("_").toLowerCase(),
    [rankRegionSort],
  );
}
