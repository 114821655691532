import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";
import { Card } from "clutch/src/Card/Card.jsx";
import { mobile } from "clutch/src/Style/style.mjs";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP } from "@/__main__/constants.mjs";
import AugmentTooltip from "@/game-tft/AugmentTooltip.jsx";
import ChampionBestItems from "@/game-tft/ChampionBestItems.jsx";
import ChampionInfo from "@/game-tft/ChampionInfo.jsx";
import {
  ChampionBackground,
  Columns,
  Left,
  Right,
} from "@/game-tft/ChampionOverview.style.jsx";
import ChampionTraits from "@/game-tft/ChampionTraits.jsx";
import { showAugmentStats } from "@/game-tft/constants.mjs";
import DistributionCircle from "@/game-tft/DistributionCircle.jsx";
import getChampionCenteredImage from "@/game-tft/get-champion-centered-image.mjs";
import StaticTFT from "@/game-tft/static.mjs";
import { getAugment, useLocalizedAugments } from "@/game-tft/use-augments.mjs";
import useSetByParam from "@/game-tft/use-set-by-param.mjs";
import getWinRateColor from "@/game-tft/util-get-winrate-color.mjs";
import { WinRate } from "@/shared/InfiniteTable.style.jsx";
import SimpleLine from "@/shared/SimpleLineChart.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ChampionOverview = ({
  champion,
  championClasses,
  championOrigins,
  indivUnitStats,
  set,
}) => {
  return (
    <Columns>
      <Left>
        <Card>
          <ChampionBackground
            style={{
              backgroundImage: getChampionCenteredImage(champion.key),
              background: `url(${getChampionCenteredImage(champion.key)})`,
            }}
          />
          <ChampionInfo champion={champion} selectedSet={set} />
          <ChampionTraits
            championClasses={championClasses}
            championOrigins={championOrigins}
            set={set}
          />
        </Card>
        <AdvancedStats indivUnitStats={indivUnitStats} />
      </Left>
      <Right>
        <ChampionStats indivUnitStats={indivUnitStats}></ChampionStats>
        <ChampionBestItems
          champion={champion}
          indivUnitStats={indivUnitStats}
          selectedSet={set}
        />
        {IS_APP && showAugmentStats ? (
          <BestAugments
            indivUnitStats={indivUnitStats}
            champKey={champion.key}
          />
        ) : (
          ""
        )}
      </Right>
    </Columns>
  );
};

const STAR_COLORS = ["#E8B55D", "var(--shade2)", "var(--shade4)"];
const STARS_LENGTH = STAR_COLORS.length;
const ITEMS_COLORS = [
  "#30D9D4",
  "var(--shade2)",
  "var(--shade4)",
  "var(--shade5)",
];
const ITEMS_LENGTH = ITEMS_COLORS.length;

const AdvancedStats = ({ indivUnitStats }) => {
  const { t } = useTranslation();

  const starDistribution = indivUnitStats?.level_stats;
  const itemDistribution = indivUnitStats?.nb_items_stats;

  if (!starDistribution || !itemDistribution) return "";

  const sortedStarDist = Object.entries(starDistribution)
    .filter(([key]) => key >= "0")
    .sort((a, b) => b[1].distribution - a[1].distribution);
  const sortedItemDist = Object.entries(itemDistribution)
    .filter(([key]) => key >= "0")
    .sort((a, b) => b[1].distribution - a[1].distribution);

  const starDistValues = [];

  if (Array.isArray(sortedStarDist) && sortedStarDist.length) {
    for (let i = 0; i < STARS_LENGTH; i += 1) {
      const starBase = sortedStarDist[i];
      if (
        !Array.isArray(starBase) ||
        typeof starBase[0] === "undefined" ||
        typeof starBase[1]?.distribution === "undefined"
      )
        continue;
      starDistValues.push({
        value: starBase[1].distribution,
        color: STAR_COLORS[i],
        label: t("tft:nStar", "{{count}} Star", {
          count: starBase[0],
        }),
      });
    }
  }

  const itemDistValues = [];

  if (Array.isArray(sortedItemDist) && sortedItemDist.length) {
    for (let i = 0; i < ITEMS_LENGTH; i += 1) {
      const itemBase = sortedItemDist[i];
      if (
        !Array.isArray(itemBase) ||
        typeof itemBase[0] === "undefined" ||
        typeof itemBase[1]?.distribution === "undefined"
      )
        continue;
      itemDistValues.push({
        value: itemBase[1].distribution,
        color: ITEMS_COLORS[i],
        label: t("tft:nItems", "{{count}} Items", {
          count: itemBase[0],
        }),
      });
    }
  }

  return (
    <Card title={t("common:advancedStats", `Advanced Stats`)}>
      <StatLayout>
        {sortedStarDist?.length ? (
          <div>
            <div className="type-caption">
              {t("tft:starDistribution", `Star Distribution`)}
            </div>
            <DistributionCircle r={43} values={starDistValues} />
          </div>
        ) : null}
        {sortedItemDist?.length ? (
          <div>
            <div className="type-caption">
              {t("tft:itemDistribution", `Item Distribution`)}
            </div>
            <DistributionCircle r={43} values={itemDistValues} />
          </div>
        ) : null}
      </StatLayout>
    </Card>
  );
};

const StatLayout = styled("div")`
  display: flex;
  justify-content: space-around;

  > div {
    display: flex;
    align-items: center;
    gap: var(--sp-8);
  }

  ${mobile} {
    flex-direction: column;
    align-items: center;
    gap: var(--sp-8);
    padding: var(--sp-8) 0;
  }
`;

const ChampionStats = ({ indivUnitStats }) => {
  const { t } = useTranslation();
  if (!indivUnitStats) return "";
  const graphData = indivUnitStats.past_patches_stats;
  const latestPatchData = graphData?.[graphData.length - 1];
  const twoPatchesAgoData = graphData?.[graphData.length - 2];
  const patchStatsToGraph = (graphData, stat, isPercent, t) => {
    if (!graphData || !stat) return [];
    if (graphData.length > 5) graphData = graphData.slice(-5);
    return graphData.map((data) => {
      return {
        x: t("common:patch", "Patch") + " " + [data.patch],
        y: isPercent ? (data[stat] * 100)?.toFixed(1) : data[stat].toFixed(1),
        isPercent: isPercent,
      };
    });
  };
  const stats = {
    "Pick Rate": {
      translationKey: "tft:pickRate",
      value: latestPatchData?.["pick_rate"],
      diff: latestPatchData?.["pick_rate"] - twoPatchesAgoData?.["pick_rate"],
      isPercent: true,
      graph: patchStatsToGraph(graphData, "pick_rate", true, t),
      avg: 12,
      lowerBetter: false,
    },
    "Avg Place": {
      translationKey: "common:avgPlace",
      value: latestPatchData?.["avg_placement"],
      diff:
        latestPatchData?.["avg_placement"] -
        twoPatchesAgoData?.["avg_placement"],
      isPercent: false,
      graph: patchStatsToGraph(graphData, "avg_placement", false, t),
      avg: 4,
      lowerBetter: true,
    },
    "Win Rate": {
      translationKey: "tft:winRate",
      value: latestPatchData?.["top_1_percent"],
      diff:
        latestPatchData?.["top_1_percent"] -
        twoPatchesAgoData?.["top_1_percent"],
      isPercent: true,
      graph: patchStatsToGraph(graphData, "top_1_percent", true, t),
      avg: 12,
      lowerBetter: false,
    },
  };
  const hasGraph =
    stats["Pick Rate"].graph?.length > 1 &&
    stats["Avg Place"].graph?.length > 1 &&
    stats["Win Rate"].graph?.length > 1;

  const chartConfig = {
    margin: { left: 8, right: 5, top: 5, bottom: 5 },
    xAxisConf: { visible: false },
    yAxisConf: { visible: false },
  };

  return (
    <Container>
      <Card title={t("common:statistics", `Statistics`)} className="statistics">
        <div className={`statColumn ${!hasGraph ? "noGraph" : ""}`}>
          {Object.keys(stats).map((stat) => {
            let value = stats[stat].value;
            const isPercent = stats[stat].isPercent;
            value = isPercent ? value * 100 : value;
            value = value?.toFixed(1);
            const avg = stats[stat].avg;
            const lowerBetter = stats[stat].lowerBetter;

            const graph = stats[stat].graph;
            const diff = isPercent ? stats[stat].diff * 100 : stats[stat].diff;
            const isPositive = !lowerBetter ? diff >= 0 : diff <= 0;

            return (
              <div className="row" key={stat}>
                <div className="left">
                  <div className="statName">
                    {t(stats[stat].translationKey, stat)}
                  </div>
                  <div className="statValue">
                    {value && !isNaN(value) ? (
                      <>
                        <WinRate
                          color={getWinRateColor(value, avg, lowerBetter)}
                        >
                          {value}
                          {isPercent ? "%" : ""}{" "}
                        </WinRate>
                        {hasGraph && (
                          <span className="diff">
                            {"("}
                            {diff >= 0 && "+"}
                            {diff.toFixed(1)}
                            {isPercent ? "%" : ""}
                            {")"}
                          </span>
                        )}
                      </>
                    ) : (
                      "-"
                    )}
                    <span></span>
                  </div>
                  {hasGraph && (
                    <div className="statDescription">
                      {t("common:vsLastPatch", "vs last patch")}
                    </div>
                  )}
                </div>
                {hasGraph && (
                  <div className="right">
                    <SimpleLine
                      showGridLines={false}
                      margin={chartConfig.margin}
                      data={graph}
                      xField="x"
                      yField="y"
                      width={105}
                      height={35}
                      xAxisConf={chartConfig.xAxisConf}
                      yAxisConf={chartConfig.yAxisConf}
                      color={isPositive ? "var(--turq)" : "var(--red)"}
                      circleRadius={5}
                      showTooltip={true}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </Card>
    </Container>
  );
};

const Container = styled("div")`
  .statistics {
    .statColumn {
      display: flex;
      flex-direction: column;
      gap: var(--sp-6);

      ${mobile} {
        padding: var(--sp-4);
      }

      &.noGraph {
        flex-direction: row;
        justify-content: space-around;

        .statName {
          color: var(--shade1);
        }

        .statDescription {
          display: none;
        }

        ${mobile} {
          padding: var(--sp-4) 0;
        }
      }

      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .statName {
      font-weight: 200;
      font-size: var(--sp-3);
      line-height: var(--sp-4);
    }
    .statValue {
      font-weight: 700;
      font-size: var(--sp-5);
      line-height: var(--sp-8);
      display: flex;
      align-items: baseline;

      .diff {
        margin-left: var(--sp-2);
        font-weight: 400;
        font-size: var(--sp-3);
        line-height: var(--sp-4_5);
        color: var(--shade2);
      }
    }
    .statDescription {
      font-weight: 400;
      font-size: var(--sp-3);
      line-height: var(--sp-3);
      color: var(--shade2);
    }
  }
`;

const BestAugments = ({ indivUnitStats, champKey }) => {
  const { t } = useTranslation();
  const set = useSetByParam(true);
  const state = useSnapshot(readState);
  const augments = state.tft[set]?.augments;
  const topAugments = indivUnitStats.top_augments;
  const localizedAugments = useLocalizedAugments(set);
  if (!topAugments) return "";
  return (
    <Card padding={0} title={t("tft:bestAugments", `Best Augments`)}>
      <AugmentRow>
        <div className="col augment">
          <div className="type-caption">{t("tft:augment", "Augment")}</div>
        </div>
        <div className="col pickRate">
          <div className="type-caption">{t("tft:pickRate", "Pick Rate")}</div>
        </div>
        <div className="col avgPlace">
          <div className="type-caption">
            {t("common:avgPlace", "Avg. Place")}
          </div>
        </div>
      </AugmentRow>

      {topAugments.slice(0, 5).map((augment, i) => {
        const augmentInfo = getAugment(augments, augment.augment_api_name);
        if (!augmentInfo) return "";
        return (
          <AugmentRow key={i}>
            <div className="col augment">
              <div className="type-caption">
                <AugmentTooltip
                  augment={augmentInfo.key}
                  className="augmentName"
                >
                  <img
                    src={StaticTFT.getAugmentImage(augmentInfo.key, set)}
                    alt={augment.augment_api_name}
                    width={32}
                    height={32}
                  />
                  <p>
                    {localizedAugments[augmentInfo.key]?.name ??
                      augmentInfo?.name}
                  </p>
                </AugmentTooltip>
              </div>
            </div>
            <div className="col pickRate">
              <div className="type-caption">
                {augment?.augment_unit_pick_rate ? (
                  <WinRate
                    color={getWinRateColor(
                      augment.augment_unit_pick_rate,
                      0.06,
                    )}
                  >
                    {(augment.augment_unit_pick_rate * 100).toFixed(1)}%
                  </WinRate>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="col avgPlace">
              <div className="type-caption">
                {augmentInfo.key?.includes("_Legend_") ? (
                  "-"
                ) : augment?.augment_unit_avg_placement ? (
                  <WinRate
                    color={getWinRateColor(
                      augment.augment_unit_avg_placement,
                      4,
                      true,
                    )}
                  >
                    {augment.augment_unit_avg_placement.toFixed(1)}
                  </WinRate>
                ) : (
                  ""
                )}
              </div>
            </div>
          </AugmentRow>
        );
      })}

      <ButtonGroup block style={{ padding: "var(--sp-2)" }}>
        <Button
          href={`/tft/champion/${champKey}/augments?set=${set.replace(
            "set",
            "",
          )}`}
        >
          {t("common:viewAll", "View All")}
        </Button>
      </ButtonGroup>
    </Card>
  );
};

const AugmentRow = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: var(--sp-2);
  color: var(--shade1);

  &:nth-child(even) {
    background: var(--shade8);
  }

  .col {
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-of-type {
      padding-inline-start: var(--sp-6);
    }

    &:last-of-type {
      padding-inline-end: var(--sp-6);
    }
  }

  .augment {
    flex: 2;
    justify-content: flex-start;

    .augmentName {
      display: flex;
      align-items: center;
      gap: var(--sp-1);

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: var(--sp-25);
        text-align: left;
      }
    }

    &:hover {
      filter: brightness(1.3);
    }
  }

  .pickRate,
  .avgPlace {
    justify-content: center;
    flex: 1;
  }
`;

export default ChampionOverview;
