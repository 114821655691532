import { styled } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";

export const TypesRow = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: var(--sp-2);

  ${mobile} {
    grid-template-columns: auto;
  }
`;
export const TypeBox = styled("div")`
  box-sizing: border-box;
  display: flex;
  border-radius: var(--br);
  padding: var(--sp-4);
  background: var(--shade5-15);
`;
export const TypeInfo = styled("div")`
  position: relative;
  flex: 1;
  margin-left: var(--sp-3);
`;
export const TypeHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--sp-2);
  margin-top: var(--sp-1);
`;
export const TypeKind = styled("div")`
  color: var(--shade3);
`;
export const TypeDescription = styled("div")`
  margin-bottom: var(--sp-2);
  color: var(--shade1);
`;
export const Bonus = styled("div")`
  display: flex;
  margin-bottom: var(--sp-1);
  align-items: flex-start;
`;
export const BonusNeeded = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--shade6);
  height: var(--sp-5);
  width: var(--sp-5);
  margin-right: var(--sp-2);
  border-radius: var(--br-sm);
`;
export const BonusDescription = styled("div")`
  flex: 1;
`;
